<template>
  <div v-if="repairs_list" class="col-md-12">
    <div>
      <div>
        <button
          class="back btn btn-sm btn-outline-secondary shadow-none"
          @click="back"
        >
          <i class="bi bi-arrow-left"></i> назад
        </button>
      </div>
      <div class="text-center">создание заявки по следующим ремонтам</div>
      <div class="wrapper">
        <div>
          <ul class="list-group">
            <li
              class="list-group-item"
              v-for="(item, idx) in repairs_list"
              :key="idx"
            >
              <span>
                {{
                  `${item.repair_number} ${item.name_instr} от ${item.date_add}`
                }}
              </span>
              <span
                ><button
                  class="btn btn-sm btn-outline-danger shadow-none"
                  @click="deleteItem(idx)"
                >
                  убрать из заявки
                </button>
              </span>
            </li>
          </ul>
        </div>
        <div>
          <SelectZapForRepair
            :dataZap="LIST_ZAP"
            :zapForRepair="zapList"
            :disabled="false"
            @addZapForRepair="addZapForRepair"
            :newZapTitle="`добавить запчасти для не гарантийных ремонтов`"
            :cenaVisible="true"
          />
        </div>
        <div>
          <textarea
            rows="5"
            class="form-control form-control-sm input-form shadow-none col-md-6"
            placeholder="комментарий к заявке"
            v-model="comment"
          ></textarea>
        </div>
        <div>
          <button
            class="btn btn-sm btn-outline-success shadow-none send"
            @click="send"
            :disabled="disbled_button_send"
          >
            отправить
          </button>
          <button
            class="btn btn-sm btn-outline-danger shadow-none"
            @click="cancel"
          >
            отмена
          </button>
        </div>
      </div>
    </div>
    <ModalMessage
      v-if="message"
      @hideMessage="hideMessage"
      message="в заявке должен остаться хотя бы один ремонт"
    />
    <div v-else />
  </div>
</template>
<script>
import { HTTPPOST, HTTPGET } from "@/api";
import ModalMessage from "@/components/ModalMessage";
import SelectZapForRepair from "@/components/SelectZapForRepair.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "NewRepairsOrders",
  computed: {
    ...mapGetters([
      "USERZAP",
      "USERZAPORDER",
      "ZAP",
      "LIST_ZAP",
      "GET_PATH_FROM",
      "SELECTREPAIRSUSER"
    ])
  },
  components: {
    ModalMessage,
    SelectZapForRepair
  },
  data: () => ({
    repairs_list: null,
    message: false,
    comment: "",
    zapList: [],
    disbled_button_send: false
  }),
  mounted() {
    HTTPGET({
      url: "repairs/repairs_list.php",
      params: {
        id: this.SELECTREPAIRSUSER
      }
    }).then(resp => (this.repairs_list = resp.data.repairs));
  },
  methods: {
    ...mapActions(["GET_ZAP"]),
    getNewOrderZap() {
      this.GET_ZAP();
    },
    addZapForRepair(zap) {
      this.zapList.push(zap);
    },
    deleteItem(idx) {
      if (this.repairs_list.length > 1) {
        this.repairs_list.splice(idx, 1);
      } else {
        this.message = true;
      }
    },
    hideMessage() {
      this.message = false;
    },
    cancel() {
      this.$router.push("/repairs/repairs-list");
    },
    send() {
      this.disbled_button_send = true;
      HTTPPOST({
        url: "repairs/repairs_orders/add_new_repairs_order.php",
        data: {
          data: this.repairs_list,
          comment: this.comment,
          zap: this.ZAP
        }
      })
        .then(() =>
          this.$router.push("/repairs/repairs-orders-list/not-finished")
        )
        .then(() => (this.disbled_button_send = false));
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  div {
    margin-bottom: 10px;
  }
}
li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.send {
  margin-right: 10px;
}
.back {
  margin: 5px;
}
</style>
